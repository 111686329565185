<template>
  <v-row no-gutters id="trend-ip-address__chart">
    <v-col md="12" lg="12">
      <loader
        :loading="isLoading || areAccountsLoading"
        :size="50"
        overlay-color="grey"
      />
      <template v-if="isChartLoaded">
        <widget-legend
          :chart-ref="chartRef"
          :default-mailbox-providers="defaultMailboxProviders"
          :metric="metric"
          :metrics="ipTrendsMetrics"
          :trends-percentages="trendsPercentage"
          @selected-metric="metric = $event"
        />

        <line-chart
          :styles="chartStyles"
          :chart-data="mailboxProviderChart"
          :options="chartOptions"
          @legend-initialised="intializeLegend"
        />
      </template>
    </v-col>
  </v-row>
</template>
<script>
import { LineChart } from "../charts";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { MailboxProviderTrends } from "@/mixins";
import {
  defer,
  isEmpty,
  getPropArr,
  hasAnyPropsValue,
  getSelectedFilterDayDate,
} from "@/utils/common.utils";
import { WidgetLegend } from "../shared";
import isEqual from "lodash/isEqual";

/**
 * Mailbox provider trend by Ip address
 */
export default {
  name: "MailboxProvidersIpTrends",
  /**
   * -------------Mixins -------------------
   */
  mixins: [MailboxProviderTrends],
  /**
   * -------------Components -------------------
   */
  components: {
    LineChart,
    WidgetLegend,
  },
  /**
   * -------------- Props -------------
   */
  props: {
    selectedDay: {
      type: Number,
      required: true,
    },
  },
  /**
   * ---------------- Data properties ------------------
   */
  data() {
    return { isLoading: false };
  },
  /**
   * ---------------- Computed properties ------------------
   */
  computed: {
    ...mapGetters({
      sendingIPs: "account/sendingIPs",
      isAccountChanged: "ui/isAccountChanged",
      selectedAccount: "ui/selectedAccount",
      mailboxProviders: "account/mailboxProviders",
      areAccountsLoading: "account/areAccountsLoading",
      inboxRateTrendWidget: "settings/inboxRateTrendWidget",
      mailboxProviderTrends: "account/mailboxProviderIpTrends",
    }),
    /**
     * Request payload to fetch the maiilbox providers ip trends
     * @type {Object}
     */
    trendsPayload() {
      if (!hasAnyPropsValue(this.inboxRateTrendWidget?.filters)) return {};
      let { domains, ip_addresses } = this.inboxRateTrendWidget?.filters;

      return {
        account_ids: this.selectedAccount?.account_id,
        domains: getPropArr(domains, "seed_domains")?.join(","),
        ip_addresses: ip_addresses?.join(","),
        ...getSelectedFilterDayDate(this.selectedDay),
      };
    },
    /**
     * Mailbox provider ip trends filters  which are peristed in the store
     * @type {Object}
     */
    appliedFilters() {
      if (!hasAnyPropsValue(this.inboxRateTrendWidget?.filters)) return {};
      let { domains, ip_addresses } = this.inboxRateTrendWidget?.filters;
      return {
        domains,
        ip_addresses,
      };
    },
    /**
     * Checks if the mailbox provider ip trends chart have filters applied
     * @type {Boolean}
     */
    hasIpAddressFilters() {
      return !isEmpty(this.appliedFilters.ip_addresses);
    },
    /**
     * Ip trends meterics to be shown at the radio button
     */
    ipTrendsMetrics() {
      const MISSING = "missing_rate";
      return this.metrics.filter(({ value }) => value !== MISSING);
    },
  },
  /**
   * ---------------- Methods ------------------
   */
  methods: {
    /**
     * Maps actions from the store
     */
    ...mapActions({
      getMailboxProviderIpTrends: "account/getMailboxProviderIpTrends",
      getSendingIPs: "account/getSendingIPs",
      setSnackbar: "ui/setSnackbar",
      getMailboxProvidersList: "account/getMailboxProviders",
    }),
    /**
     * Maps mutations from the store
     */
    ...mapMutations({
      setSeletedAccountChanged: "ui/SET_SELECTED_ACCOUNT_CHANGED",
      setInboxRateWidgetFilters:
        "settings/SET_INBOX_RATE_TREND_WIDGET_SETTINGS",
      setTrendsLoading: "settings/SET_TRENDS_LOADING",
      setTrendWidgetFiltersLoading: "settings/SET_TREND_WIDGET_FILTERS_LOADING",
    }),
    /**
     * Fetches list of mailbox providers list for selected account
     */
    async getMailboxProviders() {
      try {
        const params = { account_id: this.selectedAccount?.account_id };
        this.setTrendWidgetFiltersLoading(true);
        await this.getMailboxProvidersList(params);
      } finally {
        defer(() => this.setTrendWidgetFiltersLoading(false), 500);
      }
    },
    /**
     * Fetches the Mailbox provider ip trends list from the api resource
     * @returns {Array} List of Mailbox provider ip trends
     */
    async getMailboxTrends() {
      try {
        this.setIsLoading(true);
        await this.getMailboxProviderIpTrends(this.trendsPayload);
      } catch {
        this.setIsLoading(false);
      }
    },
    setIsLoading(val) {
      this.isLoading = val;
    },
    /**
     * Fetches sending ips from the api resource using debounce
     */
    async getSendingIpsList() {
      try {
        this.setIsLoading(true);

        this.setTrendsLoading(true);
        await this.getSendingIPs({
          account_id: this.selectedAccount.account_id,
        });
        await this.getMailboxProviders();
      } finally {
        defer(() => this.setIsLoading(false), 1000);
        this.setTrendsLoading(false);
      }
    },
    /**
     * Sets default ip address values in the ip address filters
     * if the account selected have been changed
     */
    setDefaultIpAddress() {
      const ip_addresses = !isEmpty(this.sendingIPs)
        ? [this.sendingIPs[0]?.sending_ip]
        : [];

      let domains;
      const providerCount = this.mailboxProviders.length;
      if (providerCount >= 4) {
        domains = this.mailboxProviders.slice(0, 4);
      } else {
        domains = this.mailboxProviders.slice();
      }

      this.setInboxRateWidgetFilters({
        filters: { ip_addresses, domains },
      });
      if (isEmpty(ip_addresses)) this.isLoading = false;
    },
    /**
     * Returns the ip address filters applied by the user and
     * which are persisted in the store
     * @returns {Object}
     */
    getFilterProviders() {
      let ipFilters = {};
      const { filters } = this.inboxRateTrendWidget;

      if (filters?.ip_addresses && !isEmpty(filters?.ip_addresses)) {
        filters?.ip_addresses.forEach(
          (ipAddress) => (ipFilters[ipAddress] = [])
        );
      }
      return ipFilters;
    },
    /**
     * Checks whether the account selected by the user is changed or not
     */
    async checkSelectedAccountChange() {
      await this.getSendingIpsList();
      if (this.isAccountChanged) {
        this.setSeletedAccountChanged(false);
        await this.setDefaultIpAddress();
      } else if (!this.hasIpAddressFilters) await this.setDefaultIpAddress();
      else this.initialiseTrendsChart();
    },
  },
  /**
   * ---------------- Watching properties ------------------
   */
  watch: {
    async "selectedAccount.account_id"(newVal, oldVal) {
      if (!this.isChartMounted && !newVal && !oldVal) return;

      await this.getSendingIpsList();
      await this.setDefaultIpAddress();
    },
    appliedFilters(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.delayChartInitialisation();
      }
    },
  },
  /**
   * Mounted lifecycle hook
   */
  mounted() {
    // Set default ip address and then intialises the chart
    this.checkSelectedAccountChange();
  },
};
</script>

<template>
  <div id="mailbox-provider-trends">
    <loader
      :size="50"
      overlayColor="grey"
      :loading="loading || isChartLoading"
    />
    <template v-if="hasTrends && isChartLoaded">
      <widget-legend
        :chart-ref="chartRef"
        :default-mailbox-providers="defaultMailboxProviders"
        :metric="metric"
        :metrics="metrics"
        :trends-percentages="trendsPercentage"
        @selected-metric="metric = $event"
      />
      <line-chart
        :styles="chartStyles"
        :chart-data="mailboxProviderChart"
        :options="chartOptions"
        @legend-initialised="intializeLegend"
      />
    </template>
  </div>
</template>

<script>
import { MailboxProviderTrends } from "@/mixins";
import LineChart from "@/components/charts/LineChart.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import isEqual from "lodash/isEqual";
import {
  isEmpty,
  getPropArr,
  hasAnyPropsValue,
  getSelectedFilterDayDate,
  defer,
} from "@/utils/common.utils";
import WidgetLegend from "@/components/shared/WidgetLegend.vue";

/**
 * Mailbox provider chart widget
 */
export default {
  name: "MailboxProviderTrend",
  /**
   * -------------Mixins -------------------
   */
  mixins: [MailboxProviderTrends],
  /**
   * ---------------- Components ------------------
   */
  components: {
    LineChart,
    WidgetLegend,
  },
  /**
  |--------------------------------------------------
  | Props
  |--------------------------------------------------
  */
  props: {
    selectedDay: { type: [String, Number], required: true },
  },
  /**
   * ---------------- Data properties ------------------
   */
  data() {
    return {
      trendsPercentage: [],
      isChartInitialising: false,
      loading: false,
      blockChartInitialisation: false,
      overrideMixinsWatcher: true,
    };
  },
  /**
   * ---------------- Methods ------------------
   */
  methods: {
    /**
     * Maps store actions
     */
    ...mapActions({
      setSnackbar: "ui/setSnackbar",
      getMailboxProvidersList: "account/getMailboxProviders",
      getMailboxProviderTrends: "account/getMailboxProviderTrends",
    }),
    /**
     * Maps store mutation
     */
    ...mapMutations({
      setSeletedAccountChanged: "ui/SET_SELECTED_ACCOUNT_CHANGED",
      setDomainFilters: "settings/SET_INBOX_RATE_TREND_WIDGET_SETTINGS",
      setMailboxProvidersChanged: "settings/SET_MAILBOX_PROVIDERS_CHANGED",
      setTrendWidgetFiltersLoading: "settings/SET_TREND_WIDGET_FILTERS_LOADING",
    }),
    /**
     * Fetches list of mailbox providers list for selected account
     */
    async getMailboxProviders(params) {
      try {
        this.loading = true;
        this.setTrendWidgetFiltersLoading(true);
        await this.getMailboxProvidersList(params);
      } finally {
        defer(() => {
          this.loading = false;
          this.setTrendWidgetFiltersLoading(false);
        }, 500);
      }
    },
    /**
     * getFilterProviders
     * @returns {Object} Mailbox provider filters applied by the user
     */
    getFilterProviders() {
      let filterMailboxProviders = {};

      if (hasAnyPropsValue(this.inboxRateTrendWidget?.filters)) {
        this.inboxRateTrendWidget?.filters?.domains.forEach(
          ({ name }) => (filterMailboxProviders[name] = [])
        );
      }

      return filterMailboxProviders;
    },
    /**
     * Fetches mailbox provider trends from the store
     */
    async getMailboxTrends(params = this.trendsReqParams) {
      try {
        this.loading = true;
        await this.getMailboxProviderTrends(params);
      } finally {
        defer(() => (this.loading = false), 500);
      }
    },
    /**
     * Applies and persists mailbox provider filters
     * in mailbox provider filter dropdown
     */
    async applyTrendsFilter() {
      const params = { account_id: this.selectedAccount?.account_id };
      await this.getMailboxProviders(params);

      let domains;
      if (!isEmpty(this.mailboxProviders) && isEmpty(this.appliedFilters)) {
        const providerCount = this.mailboxProviders.length;
        if (providerCount >= 4) {
          domains = this.mailboxProviders.slice(0, 4);
        } else {
          domains = this.mailboxProviders.slice();
        }

        this.reInitialiseChart(async () => {
          await this.setDomainFilters({ filters: { domains } });
          await this.initialiseTrendsChart();
        });
      } else if (!isEmpty(this.appliedFilters)) {
        await this.initialiseTrendsChart();
        this.blockChartInitialisation = false;
      } else {
        if (this.selectedAccount?.account_id) {
          await this.initialiseTrendsChart();
          this.blockChartInitialisation = false;
        }
      }
    },
    /**
     * Reintialise the chart with blocking state changes
     * to prevent unwanted renders of the chart
     */
    async reInitialiseChart(executer) {
      await (this.blockChartInitialisation = true);
      await executer();
      this.blockChartInitialisation = false;
    },
  },
  /**
   * ---------------- Computed properties ------------------
   */
  computed: {
    ...mapGetters({
      selectedAccount: "ui/selectedAccount",
      isAccountChanged: "ui/isAccountChanged",
      mailboxProviders: "account/mailboxProviders",
      inboxRateTrendWidget: "settings/inboxRateTrendWidget",
      mailboxProviderTrends: "account/mailboxProviderTrends",
      areMailboxProvidersChanged: "settings/areMailboxProvidersChanged",
    }),
    isDashboard() {
      return this.$route.path === this.$paths.dashboard;
    },
    /**
     * Trends api request params
     * @type {Object}
     */
    trendsReqParams() {
      let domains =
        this.appliedFilters && !isEmpty(this.appliedFilters)
          ? getPropArr(this.appliedFilters, "name").join(",")
          : "";

      return {
        mailbox_providers: domains,
        account_ids: this.selectedAccount?.account_id,
        ...getSelectedFilterDayDate(this.selectedDay),
      };
    },
    /**
     * Checks whether mailbox trends are present
     * @type {Boolean}
     */
    hasTrends() {
      return !!this.mailboxProviderTrends;
    },
    /**
     * Mailbox provider ip trends applied filters which are persisted in the store
     * @type {Object}
     */
    appliedFilters() {
      return this.inboxRateTrendWidget?.filters?.domains;
    },
  },
  /**
   * ---------------- Watching properties ------------------
   */
  watch: {
    async "selectedAccount.account_id"(newVal, oldVal) {
      if (!this.isChartMounted && !newVal && !oldVal) return;
      const isAccountChanged = newVal !== oldVal;

      await (this.blockChartInitialisation = true);
      await this.setDomainFilters({ filters: { domains: [] } });
      await this.applyTrendsFilter(isAccountChanged);
    },
    /** Fetches new trends when filters value is changed */
    appliedFilters(val, oldVal) {
      if (!isEqual(val, oldVal)) {
        if (!this.blockChartInitialisation) this.delayChartInitialisation();
      }
    },
    selectedDay() {
      this.reInitialiseChart(this.initialiseTrendsChart);
    },
    selectedMetric() {
      this.reInitialiseChart(this.initialiseTrendsChart);
    },
  },
  /**
   * ---------------- Before Mount hook ------------------
   */
  beforeMount() {
    this.applyTrendsFilter();
  },
};
</script>

import { SORTING_ORDERS } from "@/constants";
import { mapActions, mapGetters } from "vuex";

/**
 * Common utilities required by most of the components
 * @author {Jatin Kamboj}
 */
export default {
  name: "SharedUtilsMixin",
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    const { snackbarTypes } = this.$appConfig.snackbar;
    return {
      snackbarTypes,
    };
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    /**
     * Maps vuex getters in the component
     */
    ...mapGetters({
      selectedAccountId: "ui/selectedAccountId",
    }),
  },
  /**
  |--------------------------------------------------
  | Watchers
  |--------------------------------------------------
  */
  watch: {
    /**
     * Gets exceuted when selected account id is changed and executes updateData method defined in the parent component
     * @description updateData must be defined in the parent component
     */
    selectedAccountId(val) {
      const hasMethod = !!this["hasMethod"];
      hasMethod && this.updateData(val);
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    ...mapActions({
      setSnackbar: "ui/setSnackbar",
    }),
    /**
     * Computes the sorting configuration of the listing to be shown in the table
     * @param {String} prop Name of the property in the component which holds the properties of table sorting
     * @param {String} sortOrder Default sort order of the listing
     * @returns {Object}
     */
    getSortConfig(prop = "params", sortOrder) {
      const sortConfig = this[prop]["q[s]"]
        ? this[prop]["q[s]"]?.split(" ")
        : null;

      return {
        sortBy: sortConfig?.[0] ?? SORTING_ORDERS.asc,
        sortOrder: sortConfig?.[1] ?? sortOrder,
      };
    },
  },
};

<script>
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

/**
 * Common line chart to be used in the application
 */
export default {
  name: "LineChart",
  extends: Line,
  /**
   * ----------- Custom events -----------
   */
  emits: ["legend-initialised"],
  /**
   * Mixin to make the props reactive in nature
   * Thus chart gets re rendered when the props are changed
   */
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null,
      required: true,
    },
    options: {
      type: Object,
      default: null,
      required: true,
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
    this.$emit("legend-initialised", this);
  },
};
</script>

<template>
  <v-btn
    text
    :outlined="isBtnOutlined"
    :disabled="!hasData"
    :loading="isExporting"
    class="csv-button"
    :class="[buttonClasses.btn, { 'cursor-not-allowed': !hasData }]"
  >
    <!-- Creates json to csv file -->
    <download-csv
      :fields="fields"
      :labels="labels"
      :name="fullName"
      :data="data"
      @export-started="setIsLoading(true)"
      @export-finished="setIsLoading(false)"
    >
      <v-icon :class="buttonClasses.icon" left> mdi-download </v-icon>
      <span :class="buttonClasses.btnText"> {{ buttonText }}</span>
    </download-csv>
  </v-btn>
</template>

<script>
import DownloadCsv from "vue-json-csv";
import { isEmpty } from "@/utils/common.utils";

export default {
  name: "CsvButton",
  components: {
    // Component which converts json in to csv file
    DownloadCsv,
  },
  /**
   * Props for the component
   */
  props: {
    /**
     * Json data to be added in the csv file
     * @type {Array} Data to be added into teh csv file
     */
    data: {
      type: Array,
      required: true,
    },
    /**
     * Button text to be diplayed in the ui
     */
    buttonText: {
      type: String,
      default: "Download CSV",
    },
    /**
     * FIle name of the csv which is to be dowloaded
     */
    fileName: {
      type: String,
      required: true,
    },
    /**
     * All the json data properties which needs to be exported in the csv file
     * @type {Array}
     */
    fields: {
      type: Array,
      required: true,
    },
    /**
     * Determines whether the csv records are being loaded
     * @type {Boolean}
     */
    loading: {
      type: Boolean,
      required: true,
    },
    /**
     * Csv file headers
     */
    labels: {
      type: Object,
    },
    /**
     * Color of the button
     */
    color: {
      type: String,
      default: "secondary",
    },
  },
  /**
   * ------------------ Data properties ----------------------
   */
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    /**
     * Full name of the csv file to be dowloaded
     */
    fullName() {
      return `${this.fileName}.csv`;
    },
    /**
     * Checks whether the csv file is exporting
     */
    isExporting() {
      return this.isLoading || this.loading;
    },
    /**
     * Checks if data is present for the csv file generation
     * @type {Boolean}
     */
    hasData() {
      return this.isExporting ? true : !isEmpty(this.data);
    },
    /**
     * buttonClasses
     * Computed the classes for the csv button on the basis of color
     */
    buttonClasses() {
      const { primary } = this.$appConfig.csvButton.color;

      let classes = {
        icon: "text-dark-grey",
        btnText: "my-1 text-dark-grey font-weight-bold",
        btn: "mx-1 text-dark-grey btn-light-grey-border",
      };

      if (this.color === primary) {
        classes = {
          icon: "d-none",
          btnText: "white-btn-text",
          btn: "px-4 button font-weight-bold primary elevation-2",
        };
      }

      return classes;
    },
    /**
     * Is csv button outlined
     * @type {Boolean}
     */
    isBtnOutlined() {
      return this.color === this.$appConfig.csvButton.color.secondary;
    },
  },
  /**
   * -------------- Methods -------------
   */
  methods: {
    /**
     * setIsLoading
     * Sets isLoading data property
     * @param {Boolean} value
     */
    setIsLoading(val) {
      this.isLoading = val;
    },
  },
};
</script>

import { mapGetters, mapActions, mapMutations } from "vuex";
import {
  CHART_DATASET_COLORS,
  INBOX_RATE_TREND_METRICS,
} from "@/constants/app";
import { isEmpty, diffArr } from "@/utils/common.utils";
import debounce from "lodash/debounce";

const DEBOUNCE_CHART_MILLIS = 1000;

/**
 * Mailbox provider trends mixin
 * @description Mixin will be used for dashboard mailbox provider chart widgets
 * @author {Jatin Kamboj}
 */
export default {
  name: "MailboxProviderTrends",
  /**
   * -------------- Props -------------
   */
  props: {
    /**
     * Whether to show the full label on the chart widget:
     */
    showFullLabel: {
      type: Boolean,
      default: false,
    },
  },
  /**
   * -------------- Data Properties -------------
   */
  data() {
    const chartDatasetColors = Object.values(CHART_DATASET_COLORS);
    chartDatasetColors.length = 4;

    const metrics = INBOX_RATE_TREND_METRICS.map((metric, index) => ({
      ...metric,
      index,
    }));

    return {
      chartRef: null,
      chartLabels: [],
      providers: {},
      mailboxProviderChart: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        tooltips: {
          mode: "index",
          intersect: false,
          backgroundColor: CHART_DATASET_COLORS.black,
          titleFontColor: CHART_DATASET_COLORS.white,
          bodyFontColor: CHART_DATASET_COLORS.white,
          bodyAlign: "left",
          callbacks: {
            label: ({ datasetIndex, value }) => {
              let { label } = this.mailboxProviderChart.datasets[datasetIndex];
              label = label ? ` ${label}: ${value}%` : `${value}%`;
              return label;
            },
          },
        },

        hover: { mode: "nearest", intersect: true },
        animation: {
          animateScale: true,
        },
      },
      metrics,
      chartDatasetColors,
      isFilterDrawerOpen: false,
      isChartLoaded: false,
      trendsPercentage: [],
      isChartMounted: false,
      defaultMailboxProviders: [],
      isChartLoading: false,
      overrideMixinsWatcher: false,
    };
  },
  /**
   * ---------------- Watch properties ------------------
   */
  watch: {
    async selectedDay() {
      if (this.overrideMixinsWatcher) return;
      await this.initialiseTrendsChart();
    },
    async selectedMetric() {
      if (this.overrideMixinsWatcher) return;
      await this.initialiseTrendsChart();
    },
  },
  /**
   * ---------------- Computed properties ------------------
   */
  computed: {
    ...mapGetters({
      toUserTimezone: "auth/toUserTimezone",
      selectedAccount: "ui/selectedAccount",
      inboxRateTrendWidget: "settings/inboxRateTrendWidget",
    }),
    /**
     * Metric v-model for the metric tab
     * @model
     */
    metric: {
      get() {
        const { selectedMetric, activeWidget } = this.inboxRateTrendWidget;
        return selectedMetric[activeWidget.component]?.index || 0;
      },
      set(index) {
        const { activeWidget, selectedMetric } = this.inboxRateTrendWidget;
        selectedMetric[activeWidget.component] = {
          ...this.metrics[index],
          index,
        };
        this.setInboxRateMetric({ selectedMetric });
      },
    },
    /**
     * Chart styles
     */
    chartStyles() {
      const [LG_HEIGHT, SM_HEIGHT] = ["233px", "200px"];
      let height = LG_HEIGHT;

      if (this.$vuetify.breakpoint.xsOnly) {
        height = SM_HEIGHT;
      }
      return {
        height,
      };
    },
    /**
     * Selected inbox rate trend metric
     * @type {String}
     */
    selectedMetric() {
      const { activeWidget } = this.inboxRateTrendWidget;
      return this.inboxRateTrendWidget.selectedMetric[activeWidget.component]
        ?.value;
    },
  },
  /**
   * -------------- Methods -------------
   */
  methods: {
    /**
     * Maps store actions
     */
    ...mapActions({
      getMailboxProviders: "account/getMailboxProviders",
      setSnackbar: "ui/setSnackbar",
    }),
    /**
     * Maps store mutation
     */
    ...mapMutations({
      setInboxRateMetric: "settings/SET_INBOX_RATE_TREND_WIDGET_SETTINGS",
    }),
    intializeLegend(ref) {
      this.isChartMounted = true;
      this.chartRef = ref;
    },
    /**
     * Mailbox providers labels
     * @type {Array}
     */
    getDefaultMailboxProviders() {
      const { datasets } = this.mailboxProviderChart;

      this.defaultMailboxProviders = !isEmpty(datasets)
        ? datasets.map(({ label }) => ({ label, hidden: false }))
        : [];
    },
    /**
     * initialiseChartDefaultData
     * @description Intialises the Mailbox provider data in the graph whose data is not present
     *  in the trends object
     * @param {Array} trend Mailbox provider trend object which contains the data for the graph
     * @param {Object} providers Mailbox Providers selected in the filter
     * @param {String} provider Mailbox provider domain name
     */
    initialiseChartDefaultData(ipTrends, providers, provider) {
      const [trends, DEFAULT_DATA] = [Object.keys(ipTrends), 0];
      const providersValuesArr = Object.keys(providers);

      const isLastTrend = trends[trends.length - 1] === provider;
      const areProvidersCountGreater =
        trends.length < providersValuesArr.length;

      if (areProvidersCountGreater && isLastTrend) {
        // Intialises the data with 0 value for the provider whose data is not present in the trends object
        diffArr(providersValuesArr, trends).forEach((noDateIpTrends) => {
          providers[noDateIpTrends].push(DEFAULT_DATA);
        });
      }
    },
    /**
     * Finds the index of the object property in an array of objects
     * @param {Array} arr - Array in which object property is to be found
     * @param {String} property - Property name to be found in the object
     * @returns {Number} - Index of the object in an array
     */
    getPropertyIndex(arr, property) {
      for (const index of arr.keys()) {
        if (arr[index].prop === property) {
          return index;
        }
      }
    },
    /**
     * Toogles inbox mailbox provider filter drawer
     */
    toggleFilterDrawer(val) {
      this.isFilterDrawerOpen = val;
    },
    /**
    Calculates the trends
    */
    calculateTrendsPercent() {
      this.trendsPercentage.length = 0;

      this.mailboxProviderChart.datasets.forEach(({ data }) => {
        const sum = !isEmpty(data) ? this.$sum(data) : 0;

        const percent = sum
          ? ((sum / (data.length * 100)) * 100).toFixed(1)
          : 0;
        this.trendsPercentage.push(percent);
      });
    },
    /**
     * getChartLabels
     * @param {Date} dateStr Date to be converted
     * @returns Formatted date to be shown in the chart x-axis
     */
    getChartLabels(dateStr) {
      let date = this.toUserTimezone(dateStr).toDate();

      date = `${date.getMonthName()} ${date.getDate()}`;
      date && this.chartLabels.push(date);
    },
    /**
     * Determines that a day had a trend or not or the value of all
     * mailbox provider is 0 for that day
     * @returns {Boolean}
     */
    hasTrend(dayTrends, selectedMetric) {
      return !Object.values(dayTrends).every((trend) => {
        const hasTrendsValues = this.metrics.every(
          ({ value }) => trend[value] === 0
        );
        return trend[selectedMetric] === 0 && hasTrendsValues;
      });
    },
    /**
     * Initialises the datasets and the labels in the mailbox trend chart
     */
    async initialiseTrendsChart() {
      try {
        this.isChartLoading = true;
        let providers = await this.getFilterProviders();
        if (isEmpty(providers)) return (this.isChartLoading = false);

        // Stop chart initilisation if no filters are applied
        this.isChartLoading = true;
        await this.getMailboxTrends();
        this.isChartLoaded = false;
        this.chartLabels = [];
        this.mailboxProviderChart = { labels: [], datasets: [] };

        if (this.mailboxProviderTrends) {
          const trendsArr = Object.entries(this.mailboxProviderTrends).sort();

          /**
           * Iterates over the trends data and create an Object with Provider name as a key
           * and array of it's trends for a date in it
           */
          trendsArr.forEach(([date, trend]) => {
            const areTrendsPresent = this.hasTrend(trend, this.selectedMetric);
            areTrendsPresent && this.getChartLabels(date);

            for (const provider in trend) {
              if (providers[provider]) {
                if (areTrendsPresent) {
                  providers[provider].push(
                    trend[provider][[this.selectedMetric]]
                  );
                }
              } else {
                providers[provider] = [trend[provider][[this.selectedMetric]]];
              }

              /** Define this method it in the parent component where the mixin is being used */
              this.initialiseChartDefaultData(trend, providers, provider);
            }
          });

          // Adds datasets of a provider in the chart dataset object
          for (const [provider, data] of Object.entries(providers)) {
            let color = this.chartDatasetColors[
              this.mailboxProviderChart.datasets.length
            ];

            this.mailboxProviderChart.datasets.push({
              data,
              fill: false,
              tension: 0.3,
              borderWidth: 2,
              borderColor: color,
              pointStyle: "circle",
              backgroundColor: color,
              prop: provider.toLowerCase(),
              pointHoverBorderColor: color,
              label: this.getProviderName(provider),
              pointBorderColor: CHART_DATASET_COLORS.white,
            });
          }
          await this.calculateTrendsPercent();
          await this.getDefaultMailboxProviders();
          this.mailboxProviderChart.labels = this.chartLabels;
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: this.$errorMessage(error),
          type: this.$appConfig.snackbar.snackbarTypes.error,
        });
      } finally {
        this.isChartLoading = false;
        this.isChartLoaded = true;
        this.isLoading = false;
      }
    },
    /**
     * Computes the provider names
     * @param {String} provider Mailbox provider name
     * @returns {String} Provider name to display
     */
    getProviderName(provider) {
      const { capitalize } = this.$options.filters;
      const [PROVIDER_DOMAIN, SEPERATOR] = [".com", "."];

      const providerLabel =
        provider && provider.includes(PROVIDER_DOMAIN)
          ? provider?.split(SEPERATOR)[0]
          : provider;

      return !this.showFullLabel ? capitalize(providerLabel) : provider;
    },
    /**
     * Debounces chart initialisation on the basis of trends filter changes
     */
    delayChartInitialisation: debounce(function() {
      this.initialiseTrendsChart();
    }, DEBOUNCE_CHART_MILLIS),
  },
  /**
  |--------------------------------------------------
  | Mounted lifecycle hook
  |--------------------------------------------------
  */
  async mounted() {
    const params = { account_id: this.selectedAccount?.account_id };
    await this.getMailboxProviders(params);
  },
};

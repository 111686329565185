<template>
  <div id="mailbox-provider-tree-filter" class="pb-6">
    <div class="pl-8 my-3">
      <span class="body-2 text--secondary">
        {{ $dictionary.app.accountSettings.inboxMonitoring.treeFilterTitle }}
      </span>
    </div>
    <div
      v-if="isLoading"
      class="d-flex justify-center align-center border-y-grey py-3"
    >
      <span class="text--secondary">
        {{ $dictionary.app.common.mailboxProviders.loading }}
      </span>
    </div>
    <div
      v-else-if="!hasMailboxProviders && !isLoading"
      class="d-flex justify-center align-center border-y-grey py-3"
    >
      <span class="text--secondary">
        {{ $dictionary.app.common.mailboxProviders.noData }}
      </span>
    </div>
    <template v-else>
      <v-divider class="border-light-grey" />
      <v-treeview
        open-all
        hoverable
        return-object
        :items="mailboxProviders"
        color="primary"
        selected-color="primary"
        ref="mailboxProviderTree"
        id="mailbox-provider-tree"
        selection-type="independent"
      >
        <template #label="{item}">
          <v-list-item :class="{ 'pl-0': !item.children }">
            <v-icon
              v-if="!item.children"
              class="material-icons text--secondary"
              :class="{ 'pr-4': !item.children }"
            >
              subdirectory_arrow_right
            </v-icon>

            <v-list-item-action class="mr-4">
              <v-checkbox
                color="primary"
                v-model="item.selected"
                @change="selectAll(item, $event)"
                :indeterminate="isIndeterminate(item)"
                :class="{
                  'selected-records-checkbox': isIndeterminate(item),
                }"
              />
            </v-list-item-action>
            <v-list-item-content class="body-2 text--primary">
              <div class="mailbox-provider-text">
                {{ item.children ? item.region : item.name }}

                <span class="text--secondary caption">
                  {{ getMailboxProviderText(item) }}
                </span>
              </div>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-treeview>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPropArr, isEmpty, uniqByProp } from "@/utils/common.utils";
import { getAccountMailboxProviders, getMailboxProviders } from "@/services";

/**
 * Mailbox provider filter tree list
 */
export default {
  name: "MailboxProviderTreeFilter",
  /**
   * ---------------- Custom Events ------------------
   */
  emits: ["selected-providers"],
  /**
   * ---------------- Props ------------------
   */
  props: {
    getProviders: { type: Boolean, required: true, default: false },
  },
  /**
   * ---------------- Data Properties ------------------
   */
  data: () => ({
    isLoading: false,
    mailboxProviders: [],
    selectedMailboxProviders: [],
    mailboxProvidersList: [],
    timerRef: null,
  }),
  /**
   * --------------------- Watching properties ------------------------
   */
  watch: {
    /**
     * Watches mailbox providers for any change
     */
    mailboxProviders: {
      handler(val) {
        this.computeSelectedMailboxProviders(val);
      },
      deep: true,
    },
    /**
     * Fetches mailbox providers of the updated selected account
     */
    "selectedAccount.account_id"(val) {
      val && this.fetchMailboxProviders();
    },
    /**
     * Emits an event in parent component with id's of selected mailbox providers
     * @emits selected-providers
     */
    getProviders() {
      this.$emit("selected-providers", this.payload);
    },
  },
  /**
   * --------------------- Methods ------------------------
   */
  methods: {
    /**
     * Maps vuex actions in the component
     */
    ...mapActions({
      setSnackbar: "ui/setSnackbar",
    }),
    /**
     * Fetches list of all the available mailbox providers
     */
    async getMailboxProviders() {
      try {
        this.isLoading = true;
        const { data } = await getMailboxProviders();
        this.mailboxProvidersList = data ?? [];
      } catch {
        this.isLoading = false;
      }
    },
    /**
     * Computes mailbox provider name to be displayed
     * @returns {String} Mailbox provider
     */
    getMailboxProviderText({ children, selectedRecords }) {
      return children
        ? `(${selectedRecords} of ${children.length} selected)`
        : "";
    },
    /**
     * Select all child mailbox providers
     * @param {Array} item Selected Mailbox provider
     * @param {Boolean} selected Whether all the child mailbox providers are selected or not
     */
    selectAll(item, selected) {
      if (!isEmpty(item.children)) {
        item?.children.forEach(
          (mailboxProvider) => (mailboxProvider.selected = selected)
        );
      }

      this.$nextTick(() => this.toogleRegionProviders(item));
    },
    /**
     * Toogle (select and unselect) all the providers of a region if the
     */
    toogleRegionProviders(item) {
      if (!item) return;

      const selector = ({ region }) => region === item?.region;
      const parentRegion = this.mailboxProviders.findIndex(selector);

      // Parent provider region object
      const parentProvider = this.mailboxProviders[parentRegion];
      const isSelected =
        parentProvider.selectedRecords === 0 && parentProvider.selected;

      // Unselects checkbox of a region if its all providers are unselected
      if (isSelected) parentProvider.selected = false;
      else if (
        parentProvider.children &&
        parentProvider.children.length === parentProvider?.selectedRecords
      ) {
        parentProvider.selected = true;
      }
    },
    /**
     * Determines if the checkbox is inderminate or not
     * @returns {Boolean} Boolean value whether it is inderminate
     */
    isIndeterminate({ children, selectedRecords }) {
      return (
        children && selectedRecords >= 1 && children?.length !== selectedRecords
      );
    },
    /**
     * Computes selected mailbox providers count as per children mailbox providers
     * @param {Array} items Selected mailbox providers
     */
    computeSelectedMailboxProviders(items) {
      const DEFAULT_RECORDS = 0;

      items.forEach((item) => {
        if (!isEmpty(item.children)) {
          const records = item.children?.filter(({ selected }) => selected)
            ?.length;

          item.selectedRecords = records ?? DEFAULT_RECORDS;
        }
      });
    },
    /**
     * Computes mailbox providers list with required reactice properties
     * in its object record
     */
    async computeMailboxProviders() {
      if (isEmpty(this.mailboxProvidersList)) {
        return (this.mailboxProviders = []);
      }

      let [mailboxProviders, UNIQUE_PROP] = [[], "region"];
      const defaultProps = { selected: false, selectedRecords: 0 };

      uniqByProp(this.mailboxProvidersList, UNIQUE_PROP, true).forEach(
        ({ region, name }) => {
          const children = this.mailboxProvidersList
            .filter((data) => data?.region === region)
            .map((filteredProvider) => {
              const selected = this.isSelected(filteredProvider);

              return {
                ...filteredProvider,
                selected,
              };
            });

          mailboxProviders.push({
            name,
            region,
            children,
            ...defaultProps,
            selected: children.every(({ selected }) => selected),
          });
        }
      );
      this.mailboxProviders = mailboxProviders;
    },
    /**
     * Fetch mailbox providers list from the api resource
     * @description Fetches mailbox providers list and then computes them into the required value
     */
    async fetchMailboxProviders() {
      try {
        this.isLoading = true;
        const { account_id } = this.selectedAccount;
        await this.getMailboxProviders({ account_id });

        // Fetches account based mailbox providers
        const { data } = await getAccountMailboxProviders(account_id);
        this.selectedMailboxProviders = data ?? [];
        await this.computeMailboxProviders();
      } catch (error) {
        this.setSnackbar({
          value: true,
          type: this.$appConfig.snackbar.snackbarTypes.error,
          message: this.$errorMessage(error),
        });
      } finally {
        this.$nextTick(() => {
          this.isLoading = false;
        });
      }
    },
    /**
     * Determines if mailbox provider for the selected account is selected or not
     * @returns {Boolean} Is selected or not
     */
    isSelected(provider) {
      return (
        this.accountHasMailboxProviders &&
        this.selectedMailboxProviders.findIndex(
          ({ id }) => provider.id === id
        ) > -1
      );
    },
  },
  /**
   * ------------------ Computed properties -----------------
   */
  computed: {
    /**
     * Maps vuex getters in the component
     */
    ...mapGetters({
      selectedAccount: "ui/selectedAccount",
    }),
    /**
     * Determines if mailbox providers are present in the component or not
     * @type {Boolean}
     */
    hasMailboxProviders() {
      return !isEmpty(this.mailboxProviders);
    },
    /**
     * Computes whether selected account has mailbox provider settings or not
     * @type {Boolean}
     */
    accountHasMailboxProviders() {
      return !isEmpty(this.selectedMailboxProviders);
    },
    /**
     * Fetch account mailbox providers request payload
     * @type {Object}
     */
    payload() {
      if (isEmpty(this.mailboxProviders)) return [];

      let mailboxProviders = getPropArr(this.mailboxProviders, "children")
        .flat()
        .filter(({ selected }) => selected);
      return {
        mailbox_providers: [...new Set(getPropArr(mailboxProviders, "id"))],
      };
    },
  },
  /**
  |--------------------------------------------------
  | Created lifecycle hook
  |--------------------------------------------------
  */
  created() {
    this.fetchMailboxProviders();
  },
  /**
  |--------------------------------------------------
  | On component destroy
  |--------------------------------------------------
  */
  destroyed() {
    this.timerRef && clearTimeout(this.timerRef);
  },
};
</script>

<style lang="scss">
#mailbox-provider-tree {
  .selected-records-checkbox .v-input__control {
    .v-input__slot {
      .v-input--selection-controls__input {
        i {
          color: var(--v-primary-base) !important;
        }
      }
    }
  }
  .v-input--selection-controls__ripple {
    color: var(--v-primary-base);
  }

  .v-treeview-node {
    .v-treeview-node__root {
      @extend .border-bottom-grey;
    }
  }
}
</style>

import { mapActions, mapGetters } from 'vuex';
import { getSelectedFilterDayDate } from "@/utils/common.utils";

export default {
  
  props:{
    selectedDay:{
      type: Number
    },
  },

  /**
   * Send all filter data to parent component
   */
  emits:['filter'],
  data(){
    const dates = this.initDate();
    return{
      isFormValid: false,
      filters: {
        dates:dates,
        from_domains: [],
        sending_ips: [],
        seed_domains: [],
        account_ids: [],
        subject: ''
      },
      dateMenu: false,
    }
  },
  watch: {
    selectedDay(val) {
      this.filters.dates= Object.values(this.getSelectedFilterDayDate(val))
    },
  },
  computed :{
    ...mapGetters({
      accounts: "account/accounts",
      sendingDomains: "account/sendingDomains",
      sendingIPs: "account/sendingIPs",
      mailboxProviders: "account/campaignMailboxProviders"

    }),

    maxDate(){
      return new Date().toISOString().substr(0, 10);
    },

    minDate(){
      const today = new Date();
      return new Date(today.setDate(today.getDate()-180)).toISOString().substr(0, 10);
    },

    dateRangeText () {
      return this.filters.dates.join(' ~ ');
    },

    account_id(){
      return this.$route.query.account_id ? this.$route.query.account_id: 0; 
    }
  }, 

  methods:{
    getSelectedFilterDayDate,

    ...mapActions({
      getSendingDomains: "account/getSendingDomains",
      getSendingIPs: "account/getSendingIPs",
      getMailboxProviders: "account/getCampaignMailboxProviders"
    
    }),

    /**
     * set dates value 
     */
    initDate(){
      if(this.selectedDay){
        return Object.values(this.getSelectedFilterDayDate(this.selectedDay))
      } else {
        const { from_date, to_date } = this.$route.query;
        return [from_date, to_date]
      }
    },

    closeDateRange() {
      if(this.filters.dates.length ==2){
        this.filters.dates.sort();  
        this.dateMenu = false
      }
    },
    /**
    * Reset all form data
    */
    resetForm(){
      this.filters.dates=this.initDate()
      this.$refs.form.reset()
      this.$emit('filter', {})
    },

    /**
     * On Submit emitted formdata to parent component
     */
    handleSubmit(){
      const filters = {}

      Object.keys(this.filters).forEach(key =>{
        if(key == 'dates'){
          const [from_date, to_date] = this.filters.dates.sort();
          filters.from_date = from_date;
          filters.to_date = to_date;
        } else if(Array.isArray(this.filters[key])){
          filters[key] = this.filters[key].length ? this.filters[key].join(',') : undefined;
        } else if(this.filters[key]){
          filters[key] = this.filters[key];
        }
      })

      this.$emit('filter', filters)
    }
  },

  created(){
    this.getSendingDomains();
    this.getSendingIPs();
    this.getMailboxProviders({account_id: this.account_id, campaign_id: 0});
    
  }
}
<template>
  <v-card elevation="3" flat :class="cardStyle" :height="height">
    <div :class="containerClasses">
      <v-card-title
        v-if="cardTitle"
        class="text-h6 secondary--font font-weight-large"
      >
        {{ cardTitle }}
      </v-card-title>
      <v-card-text :class="bodyStyle">
        <!-- @slot Use this slot for card body -->
        <slot name="cardBody"></slot>
      </v-card-text>
      <v-card-actions class="py-0">
        <!-- @slot Use this slot for card actions -->
        <slot name="cardActions"></slot>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "CardWidget",
  /**
   * Props to be recieved by the component
   */
  props: {
    /**
     * Title of the card to be displayed
     */
    cardTitle: {
      type: String,
      default: "",
    },
    /**
     * Card body styles
     */
    bodyStyle: {
      type: String,
    },
    /**
     * Height of the card
     */
    height: {
      type: String,
      default: "auto",
    },
    /**
     * Card styles
     */
    cardStyle: {
      type: String,
      default: "",
    },
    containerClasses: {
      type: String,
      default: "py-4 px-4",
    },
  },
};
</script>
 
<template>
  <div id="trend-chart">
    <loader
      :size="50"
      overlayColor="grey"
      :loading="areSeedsloading || isChartLoading"
    />

    <template v-if="isChartLoaded">
      <widget-legend
        :chart-ref="chartRef"
        :default-mailbox-providers="defaultMailboxProviders"
        :metric="metric"
        :metrics="seedDomainMetrics"
        :trends-percentages="trendsPercentage"
        @selected-metric="metric = $event"
      />
      <line-chart
        :styles="chartStyles"
        :chart-data="mailboxProviderChart"
        :options="chartOptions"
        @legend-initialised="intializeLegend"
      />
    </template>
  </div>
</template>

<script>
import { LineChart } from "../charts";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { MailboxProviderTrends } from "@/mixins";
import {
  getPropArr,
  hasAnyPropsValue,
  isEmpty,
  getSelectedFilterDayDate,
} from "@/utils/common.utils";
import { WidgetLegend } from "../shared";
import isEqual from "lodash/isEqual";
import { seedDomainTrends } from "@/services";

/**
 * Mailbox provider chart widget
 */
export default {
  name: "SeedDomainTrends",

  /**
   * -------------Mixins -------------------
   */
  mixins: [MailboxProviderTrends],
  /**
   * ---------------- Components ------------------
   */
  components: {
    LineChart,
    WidgetLegend,
  },
  props: {
    selectedDay: {
      type: [String, Number],
      required: true,
    },
  },
  /**
   * ---------------- Data properties ------------------
   */
  data() {
    return {
      trendsPercentage: [],
      isChartInitialising: false,
      areSeedsloading: false,
      mailboxProviderTrends: [],
    };
  },
  /**
   * ---------------- Methods ------------------
   */
  methods: {
    /**
     * Maps actions from the store
     */
    ...mapActions({
      getSendingDomains: "account/getSendingDomains",
    }),
    /**
     * Maps mutations from the store
     */
    ...mapMutations({
      setInboxRateWidgetFilters:
        "settings/SET_INBOX_RATE_TREND_WIDGET_SETTINGS",
      setTrendsLoading: "settings/SET_TRENDS_LOADING",
      setSelectedAccountChanged: "ui/SET_SELECTED_ACCOUNT_CHANGED",
    }),
    /**
     * getFilterProviders
     * @returns {Object} From domains filters applied by the user
     */
    getFilterProviders() {
      let filterMailboxProviders = {};
      const { filters } = this.inboxRateTrendWidget;

      if (hasAnyPropsValue(filters) && !isEmpty(filters?.sending_domains)) {
        filters?.sending_domains.forEach(
          ({ from_domain }) => (filterMailboxProviders[from_domain] = [])
        );
      }
      return filterMailboxProviders;
    },
    /**
     * Fetches from domain trends from api resource
     */
    async getMailboxTrends(params = this.trendsReqParams) {
      try {
        const { data } = await seedDomainTrends(params);

        this.mailboxProviderTrends = data ?? {};
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: this.$errorMessage(error),
          type: this.$appConfig.snackbar.snackbarTypes.error,
        });
        this.isChartLoading = false;
      }
    },
    /**
     * Fetches Sending domain list from sending domain api resource
     */
    async getSendingDomainsList() {
      try {
        this.setTrendsLoading(true);
        await this.getSendingDomains({
          account_ids: this.selectedAccount.account_id,
        });
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: this.$errorMessage(error),
          type: this.$appConfig.snackbar.snackbarTypes.error,
        });
      } finally {
        this.setTrendsLoading(false);
      }
    },
    /**
     * Populates the seed domain trends in the chart
     */
    async populateSeedDomainTrends(isAccountDiff = false) {
      try {
        this.isChartLoading = true;
        await this.getSendingDomainsList();
        if (!this.hasFilters || isAccountDiff) {
          let sendingDomains = isEmpty(this.sendingDomains)
            ? []
            : [this.sendingDomains[0]];

          await this.setInboxRateWidgetFilters({
            filters: { sending_domains: sendingDomains },
          });

          if (isEmpty(sendingDomains)) {
            this.isChartLoading = false;
            this.isChartLoaded = true;
          }
        } else await this.initialiseTrendsChart();
      } catch {
        this.isChartLoading = false;
      }
    },
    /** Returns the seed domain name to be shown in the chart legened */
    getProviderName: (seedDomain) => seedDomain,
  },
  /**
   * ---------------- Computed properties ------------------
   */
  computed: {
    ...mapGetters({
      selectedAccount: "ui/selectedAccount",
      isAccountChanged: "ui/isAccountChanged",
      sendingDomains: "account/sendingDomains",
      inboxRateTrendWidget: "settings/inboxRateTrendWidget",
    }),
    /**
     * Seed domains meterics to be shown at the radio button
     */
    seedDomainMetrics() {
      const MISSING = "missing_rate";
      return this.metrics.filter(({ value }) => value !== MISSING);
    },
    /**
     * Sending domains api request params
     * @type {Object}
     */
    trendsReqParams() {
      let sendingDomains =
        this.appliedFilters && !isEmpty(this.appliedFilters)
          ? getPropArr(this.appliedFilters, "from_domain").join(",")
          : "";

      return {
        from_domains: sendingDomains,
        account_ids: this.selectedAccount?.account_id,
        ...getSelectedFilterDayDate(this.selectedDay),
      };
    },
    /**
     * Checks whether mailbox trends are present
     * @type {Boolean}
     */
    hasFilters() {
      const { filters } = this.inboxRateTrendWidget;
      return (
        hasAnyPropsValue(this.inboxRateTrendWidget?.filters) &&
        filters &&
        !isEmpty(filters?.sending_domains)
      );
    },
    /**
     * Inbox rate from domains applied persisted filters in he store
     * @type {Object}
     */
    appliedFilters() {
      return this.inboxRateTrendWidget?.filters?.sending_domains;
    },
  },
  /**
   * ---------------- Watching properties ------------------
   */
  watch: {
    "selectedAccount.account_id"(newVal, oldVal) {
      if (!this.isChartMounted && !newVal && !oldVal) return;
      const isAccountDiff = newVal !== oldVal;
      this.populateSeedDomainTrends(isAccountDiff);
    },
    /** Re initialises the trends chart if the filters applied are changed */
    appliedFilters(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.delayChartInitialisation();
      }
    },
  },
  /**
   * ---------------- Before mount hook ------------------
   */
  async beforeMount() {
    this.$nextTick(async () => {
      await this.populateSeedDomainTrends(this.isAccountChanged);

      if (this.isAccountChanged) {
        this.setSelectedAccountChanged(false);
      }
    });
  },
};
</script>

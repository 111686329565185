/**
 * Password validator mixin
 * @description Will be used to validate the password and confirm password
 * and applies regex validation
 * @author {Jatin Kamboj}
 */
export default {
  /**
   * -------------- Data Properties -------------
   */
  data() {
    return {
      /**
       * Password state properties
       */
      formData: {
        password: "",
        confirmPassword: "",
      },
      /**
       * Password rules
       */
      passwordRules: [
        { text: "Password must be at least 8 characters long", isValid: false },
        {
          text: "Password must contain at least one upper case character",
          isValid: false,
        },
        {
          text: "Password must contain at least one special characters (-,+!)",
          isValid: false,
        },
        { text: "Password must contain at least one number", isValid: false },
        {
          text: "Password cannot have two consecutive characters",
          isValid: false,
        },
      ],
    };
  },
  /**
   * -------------- Watch Properties -------------
   */
  watch: {
    /**
     * Validates the new password on the basis of the rules
     */
    "formData.password"(val) {
      /**
       * Password regex validators
       */
      const regexValidations = [
        /.{8,}/,
        /[A-Z]+/,
        /(?=.*?[#?!@$%^&*-])+/,
        /[0-9]+/,
      ];

      this.passwordRules.forEach((rule, i) => {
        if (i === this.passwordRules.length - 1) {
          // Validates not 2 consecutive characters
          const isValid = /^\S*(.)\1\S*/.test(val);
          rule.isValid = !isValid;
          return;
        }
        rule.isValid = regexValidations[i].test(val);
      });
    },
  },
  /**
   * -------------- Computed Properties -------------
   */
  computed: {
    /**
     * Checks if the current password is valid
     */
    isNewPasswordValid() {
      let isValid = this.passwordRules.every(({ isValid }) => isValid);

      return () => isValid || this.displayMessages.passwordInvalid;
    },
    /**
     * Checks if the new password annd current password same
     * @type {Boolean}
     */
    isConfirmPasswordSame() {
      const { password, confirmPassword } = this.formData;

      return this.isNewPasswordValid && password === confirmPassword;
    },
    /**
     * Validates the confirm password field
     * @type {Function}
     */
    validateConfirmPassword() {
      return () =>
        this.isConfirmPasswordSame || this.displayMessages.passwordMisMatch;
    },
    /**
     * Check if the new password is dirty or have some value
     * @type {Boolean}
     */
    isNewPasswordDirty() {
      return this.formData?.password?.length >= 1;
    },
  },
  /**
   * -------------- Methods -------------
   */
  methods: {
    /**
     * Returns the icon color of the validation status
     * @param {Boolean} isValid Is the valdation valid or not
     */
    getIconColor({ isValid }) {
      const [TRANSPAREMT, RED, GREEN] = ["transparent", "red", "green"];
      return !this.isNewPasswordDirty ? TRANSPAREMT : !isValid ? RED : GREEN;
    },
  },
};
